/**
 * CGW Consulting
 */
import React, { useContext } from 'react';
import { LanguageContext } from '../LanguageContext';
import { translations } from './HeroSectionTranslations';
import { Container, Row, Col } from 'react-bootstrap';
import './HeroSection.css';

const HeroSection = () => {
    const { language } = useContext(LanguageContext);
    const translation = translations[language];

    return (
        <section className="hero" id="hero">
            <Container className="h-100">
                <Row className="h-100 justify-content-center align-items-center">
                    <Col xs={12} md={8} lg={6}>
                        <div className="text-center">
                            <h1>{translation.mainHeader}</h1>
                            <p>{translation.subHeader}</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default HeroSection;
