/**
 * CGW Consulting
 */

export const translations = {
    en: {
      contact: 'Contact Us',
      nameLabel: 'Name',
      namePlaceholder: 'Enter your name',
      emailLabel: 'Email Address',
      emailPlaceholder: 'Enter your email',
      messageLabel: 'Message',
      messagePlaceholder: 'Your message',
      submitButton: 'Submit',
    },
    es: {
      contact: 'Contáctanos',
      nameLabel: 'Nombre',
      namePlaceholder: 'Ingrese su nombre',
      emailLabel: 'Correo Electrónico',
      emailPlaceholder: 'Ingrese su correo electrónico',
      messageLabel: 'Mensaje',
      messagePlaceholder: 'Tu mensaje',
      submitButton: 'Enviar',
    },
  };
  