/**
 * CGW Consulting
 */

import React, { useContext } from 'react';
import { LanguageContext } from '../LanguageContext';
import { translations } from './NavbarTranslations';
import { Navbar, Nav, Container } from 'react-bootstrap';
import './Navbar.css';

const CustomNavbar = () => {
    const { language, toggleLanguage } = useContext(LanguageContext);
    const translation = translations[language];

    const scrollToSection = (sectionId) => {
        const offsetTop = document.querySelector(sectionId).offsetTop - 50;

        window.scrollTo({
            top: offsetTop,
            behavior: 'smooth'
        });
    };

    return (
        <Navbar bg="dark" variant="dark" expand="lg" fixed="top">
            <Container>
                <Navbar.Brand href="#home">CGW Consulting</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link href="#about" onClick={() => scrollToSection('#about')}>{translation.about}</Nav.Link>
                        <Nav.Link href="#services" onClick={() => scrollToSection('#services')}>{translation.services}</Nav.Link>
                        <Nav.Link href="#contact" onClick={() => scrollToSection('#contact')}>{translation.contact}</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
                <div className="language-switch-container">
                    <span
                        className={`flag ${language === 'es' ? 'flag-inactive' : ''}`}
                        onClick={toggleLanguage}
                    >
                        🇺🇸
                    </span>
                    <span
                        className={`flag ${language === 'en' ? 'flag-inactive' : ''}`}
                        onClick={toggleLanguage}
                    >
                        🇦🇷
                    </span>
                </div>
            </Container>
        </Navbar>
    );
};

export default CustomNavbar;
