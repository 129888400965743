/**
 * CGW Consulting
 */

export const translations = {
    en: {
        mainHeader: "Transforming business through tailored solutions",
        subHeader: "Your trusted consultants."
    },
    es: {
        mainHeader:"Transformando negocios a través de soluciones a medida",
        subHeader: "Tus consultores de confianza."
    }
};
