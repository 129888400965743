/**
 * CGW Consulting
 */

import React from 'react';
import { LanguageProvider } from './components/LanguageContext';
import CustomNavbar from './components/Navbar/Navbar';
import HeroSection from './components/HeroSection/HeroSection';
import About from './components/About/About';
import Services from './components/Services/Services';
import Contact from './components/Contact/Contact';
import 'bootstrap/dist/css/bootstrap.min.css';

import './App.css';

function App() {
  return (
    <LanguageProvider>
      <div className="App">
        <CustomNavbar />
        <main>
          <HeroSection />
          <About />
          <Services />
          <Contact />
        </main>
      </div>
    </LanguageProvider>
  );
}

export default App;
