/**
 * CGW Consulting
 */

export const translations = {
    en: {
        about: 'About Us',
        subtitle: 'A legacy of excellence in consulting',
        p1: 'Founded on the confluence of expertise from the Costantino, Genolet, the waterhouse has carved a niche in the finance, accounting, and technology sectors. We are committed to providing innovative solutions that drive efficiency and growth.',
        p2: 'At CGW Consulting, our mission is to empower businesses with cutting-edge technology and strategic financial insights. We aim to be your trusted advisor, ensuring that your business thrives in a dynamic economic landscape.',
        p3: "Let's innovate and solve real-world challenges together!",
    },
    es: {
        about: 'Sobre nosotros',
        subtitle: 'Un legado de excelencia en consultoría',
        p1: 'Fundada a partir de la confluencia de conocimientos de Costantino y Genolet, la casa del agua se ha hecho un hueco en los sectores de finanzas, contabilidad y tecnología. Estamos comprometidos a brindar soluciones innovadoras que impulsen la eficiencia y el crecimiento.',
        p2: 'En CGW Consulting, nuestra misión es empoderar a las empresas con tecnología de punta y conocimientos financieros estratégicos. Nuestro objetivo es ser su asesor de confianza, garantizando que su negocio prospere en un panorama económico dinámico.',
        p3: 'Innovemos y resolvamos juntos los desafíos del mundo real!',
    }
};
