/**
 * CGW Consulting
 * 
 * Language context api
 */

import React, { useState, createContext } from 'react';
export const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
    const initialLanguage = localStorage.getItem('language') || 'en';
    const [language, setLanguage] = useState(initialLanguage);

    const toggleLanguage = () => {
        const newLanguage = language === 'en' ? 'es' : 'en';
        setLanguage(newLanguage);
        localStorage.setItem('language', newLanguage);
    };

    return (
        <LanguageContext.Provider value={{ language, toggleLanguage }}>
            {children}
        </LanguageContext.Provider>
    );
};
