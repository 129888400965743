/**
 * CGW Consulting
 */

import React, { useState, useContext } from 'react';
import { Container, Form, Button, Row, Col } from 'react-bootstrap';
import { LanguageContext } from '../LanguageContext';
import { translations } from './ContactTranslations';

import './Contact.css';

const Contact = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
    });

    const { language } = useContext(LanguageContext);
    const translation = translations[language];


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(formData);
    };

    return (
        <section id="contact" className="contact-section">
            <Container>
                <Row className="justify-content-center">
                    <Col lg={8}>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group className="mb-3" controlId="formBasicName">
                                <Form.Label>{translation.nameLabel}</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    placeholder={translation.namePlaceholder}
                                    required
                                />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>{translation.emailLabel}</Form.Label>
                                <Form.Control
                                    type="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    placeholder={translation.emailPlaceholder}
                                    required
                                />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicMessage">
                                <Form.Label>{translation.messageLabel}</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    name="message"
                                    value={formData.message}
                                    onChange={handleChange}
                                    placeholder={translation.messagePlaceholder}
                                    required
                                />
                            </Form.Group>

                            <Button variant="primary" type="submit">
                                {translation.submitButton}
                            </Button>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default Contact;
