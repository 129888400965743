/**
 * CGW Consulting
 */
import React, { useContext } from 'react';
import { LanguageContext } from '../LanguageContext';
import profileImage from '../../assets/logo_about.png';
import { translations } from './AboutTranslations';
import { Container, Row, Col } from 'react-bootstrap';
import './About.css';

const About = () => {
    const { language } = useContext(LanguageContext);
    const translation = translations[language];

    return (
        <section id="about" className="about-section">
            <Container>
                <Row className="align-items-center">
                    <Col lg={6} md={5} className="order-md-1 about-image text-center">
                        <img src={profileImage} alt="Our logo" className="img-fluid" />
                    </Col>
                    <Col lg={6} md={7} className="order-md-2 about-text">
                        <div className="about-heading">
                            <h2>{translation.about}</h2>
                            <p>{translation.subtitle}</p>
                        </div>
                        <p>{translation.p1}</p>
                        <p>{translation.p2}</p>
                        <p>{translation.p3}</p>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default About;
