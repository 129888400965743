/**
 * CGW Consulting
 */

export const translations = {
    en: {
        about: 'About',
        services: 'Services',
        portfolio: 'Portfolio',
        contact: 'Contact'
    },
    es: {
        about: 'Sobre nosotros',
        services: 'Servicios',
        portfolio: 'Portafolio',
        contact: 'Contacto'
    }
};
