/**
 * CGW Consulting
 */

import React, { useContext } from 'react';
import { LanguageContext } from '../LanguageContext';
import { translations } from './ServicesTranslations';
import { Container, Row, Col, Card } from 'react-bootstrap';
import './Services.css';

const Services = () => {
    const { language } = useContext(LanguageContext);
    const translation = translations[language];

    return (
        <section id="services" className="services-section">
            <Container>
                <Row className="justify-content-center mb-5">
                    <Col className="text-center">
                        <h2>{translation.services}</h2>
                        <p>{translation.servicesDescription}</p>
                    </Col>
                </Row>
                <Row>
                    <Col md={4} className="mb-4">
                        <Card className="service-card">
                            <Card.Img variant="top" src="../../assets/logo_about.png" />
                            <Card.Body>
                                <Card.Title>{translation.service1Title}</Card.Title>
                                <Card.Text>{translation.service1Text}</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4} className="mb-4">
                        <Card className="service-card">
                            <Card.Img variant="top" src="../../assets/logo_about.png" />
                            <Card.Body>
                                <Card.Title>{translation.service2Title}</Card.Title>
                                <Card.Text>{translation.service2Text}</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4} className="mb-4">
                        <Card className="service-card">
                            <Card.Img variant="top" src="../../assets/logo_about.png" />
                            <Card.Body>
                                <Card.Title>{translation.service3Title}</Card.Title>
                                <Card.Text>{translation.service3Text}</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default Services;
