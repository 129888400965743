/**
 * CGW Consulting
 */

export const translations = {
    en: {
      services: 'Our Services',
      servicesDescription: 'We offer a range of services to empower your business growth.',
      service1Title: 'Business Strategy',
      service1Text: 'Comprehensive analysis and strategic planning to drive success.',
      service2Title: 'Financial Consulting',
      service2Text: 'Expert financial advice to optimize profitability and efficiency.',
      service3Title: 'Technology Solutions',
      service3Text: 'Innovative tech solutions tailored to your business needs.',
    },
    es: {
      services: 'Nuestros Servicios',
      servicesDescription: 'Ofrecemos una gama de servicios para potenciar el crecimiento de su negocio.',
      service1Title: 'Estrategia Empresarial',
      service1Text: 'Análisis integral y planificación estratégica para impulsar el éxito.',
      service2Title: 'Consultoría Financiera',
      service2Text: 'Asesoramiento financiero experto para optimizar la rentabilidad y la eficiencia.',
      service3Title: 'Soluciones Tecnológicas',
      service3Text: 'Soluciones tecnológicas innovadoras adaptadas a las necesidades de su empresa.',
    },
  };
  
  